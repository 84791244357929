import styles from './InlineNav.module.scss';
import { QuickLink } from '../../../../types';
import { Anchor } from '../../../shared/Anchor';

export interface InlineNavProps {
  links: QuickLink[];
}

export function InlineNav({ links }: InlineNavProps) {
  return (
    <ul className={styles.list}>
      {links.map((link) => (
        <li key={link.text}>
          <Anchor
            to={link.url}
            openInNewTab={link.openInNewTab}
            className={styles.anchor}
          >
            {link.text}
          </Anchor>
        </li>
      ))}
    </ul>
  );
}

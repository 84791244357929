import cn from 'classnames';
import { SocialNetworkLink } from '../../../../types';
import artsyIcon from '../../../../assets/images/artsy-icon.svg';
import styles from './SocialNetworkNav.module.scss';

export interface SocialNetworkNavProps {
  links: SocialNetworkLink[];
}

const iconsMap: Record<string, string> = {
  twitter: 'fab fa-x-twitter',
  threads: 'fab fa-threads',
  linkedin: 'fab fa-linkedin',
  instagram: 'fab fa-instagram',
  facebook: 'fab fa-facebook',
  youtube: 'fab fa-youtube',
  blog: 'fab fa-blogger',
  flickr: 'fab fa-flickr',
  tiktok: 'fab fa-tiktok',
  streamly: 'fa-solid fa-circle-play',
  whatsapp: 'fab fa-square-whatsapp',
};

const customIconsMap: Record<string, string> = {
  artsy: artsyIcon,
};

export function SocialNetworkNav({ links }: SocialNetworkNavProps) {
  return (
    <ul className={styles.list}>
      {links.map((link) => (
        <li key={link.type}>
          <a
            href={link.url}
            className={styles.anchor}
            title={link.text}
            target="_blank"
            rel="noreferrer"
          >
            {iconsMap[link.type] ? (
              <span className={cn(styles.icon, iconsMap[link.type])} />
            ) : (
              <span className={cn(styles.icon, styles.customIcon)}>
                <img src={customIconsMap[link.type]} alt={link.type} />
              </span>
            )}
          </a>
        </li>
      ))}
    </ul>
  );
}

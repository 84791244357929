import cn from 'classnames';
import styles from './ClassicFooter.module.scss';
import { QuickLink, SocialNetworkLink, UploadedImage } from '../../../types';
import { HTMLtoJSX } from '../../../helpers';
import { useHubContext } from '../../hub/shared/context/hubContext';
import { Anchor } from '../../shared/Anchor';
import { ImageTag } from '../../shared/ImageTag/ImageTag';
import { ColumnNav } from './ColumnNav';
import { InlineNav } from './InlineNav';
import { SocialNetworkNav } from './SocialNetworkNav';

export interface ClassicFooterProps {
  primaryNav: QuickLink[];
  primaryNavHeading?: string;
  secondaryNav?: QuickLink[];
  secondaryNavHeading?: string;
  publicNav?: QuickLink[];
  socialNetworkNav?: SocialNetworkLink[];
  logo: UploadedImage;
  logoAlt?: string;
  copyright: string;
}

export default function ClassicFooter({
  logo,
  logoAlt,
  primaryNav,
  primaryNavHeading,
  secondaryNav,
  secondaryNavHeading,
  publicNav,
  socialNetworkNav,
  copyright,
}: ClassicFooterProps) {
  const { indexPath } = useHubContext();

  const logoContent = logo?.path && (
    <Anchor to="/" pathPrefix={indexPath}>
      <ImageTag src={logo.path} alt={logoAlt} lazy={false} />
    </Anchor>
  );
  const socialNetworkNavContent = socialNetworkNav &&
    socialNetworkNav.length > 0 && (
      <SocialNetworkNav links={socialNetworkNav} />
    );

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {logoContent && (
              <div
                data-testid="classic-footer-mobile-logo"
                className={styles.mobileLogo}
              >
                {logoContent}
              </div>
            )}
            {socialNetworkNavContent && (
              <nav
                data-testid="classic-footer-social-network-mobile"
                className={styles.socialNetworkNavMobile}
              >
                {socialNetworkNavContent}
              </nav>
            )}
            <hr className={cn(styles.line, styles.mobileLine)} />
            <div className={styles.upperFooter}>
              {primaryNav.length > 0 && (
                <div
                  data-testid="classic-footer-primary-nav-wrap"
                  className={styles.primaryNav}
                >
                  <ColumnNav links={primaryNav} heading={primaryNavHeading} />
                </div>
              )}
              {secondaryNav && secondaryNav.length > 0 && (
                <div
                  data-testid="classic-footer-secondary-nav-wrap"
                  className={styles.secondaryNav}
                >
                  <ColumnNav
                    links={secondaryNav}
                    heading={secondaryNavHeading}
                  />
                </div>
              )}
            </div>
            <hr className={styles.line} />
            <div className={styles.lowerFooter}>
              <div className={styles.lowerFooterLeft}>
                {logoContent && (
                  <div
                    data-testid="classic-footer-desktop-logo"
                    className={styles.desktopLogo}
                  >
                    {logoContent}
                  </div>
                )}
                {publicNav && publicNav.length > 0 && (
                  <nav
                    data-testid="classic-footer-public-nav-wrap"
                    className={styles.publicNav}
                  >
                    <InlineNav links={publicNav} />
                  </nav>
                )}
              </div>
              <div className={styles.lowerFooterRight}>
                {socialNetworkNavContent && (
                  <nav
                    data-testid="classic-footer-social-network-bottom"
                    className={styles.socialNetworkNavDesktop}
                  >
                    {socialNetworkNavContent}
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
        {copyright && (
          <div className="row">
            <div className="col-xs-12">
              <div className={styles.copyright}>{HTMLtoJSX(copyright)}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

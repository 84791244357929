import styles from './ColumnNav.module.scss';
import { QuickLink } from '../../../../types';
import { divideArrayIntoParts } from '../../../../helpers';
import { Anchor } from '../../../shared/Anchor';

export interface ColumnNavProps {
  links: QuickLink[];
  heading?: string;
}

export function ColumnNav({ links, heading }: ColumnNavProps) {
  const lists = divideArrayIntoParts<QuickLink>(links, 3).filter(
    (list) => list.length > 0,
  );
  const columnClassName = `col-xs-${12 / lists.length}`;

  return (
    <div className={styles.wrapper}>
      {heading && <h4 className={styles.heading}>{heading}</h4>}
      <nav className="row">
        {lists.map((list, index) => (
          <ul key={index} className={columnClassName}>
            {list.map((link) => (
              <li key={link.text} className={styles.listItem}>
                <Anchor
                  to={link.url}
                  openInNewTab={link.openInNewTab}
                  className={styles.anchor}
                >
                  {link.text}
                </Anchor>
              </li>
            ))}
          </ul>
        ))}
      </nav>
    </div>
  );
}
